// COMPONENTS
import React from 'react'

// Text counter component
const TextCounter = ({ content, chars }) => {
  const { fail, success, charLimit } = content ? content : { 
    fail: 'Too many characters!',
    success: 'characters left',
    charLimit: 250
  }
  const numChars = chars ? chars.length : 0
  const charsLeft = (charLimit-numChars > 0) ? charLimit-numChars : 0
  const valid = (numChars <= charLimit)

  return (
    <p className={`text-${valid ? 'success': 'danger'}`}>
      {`${charsLeft} ${valid ? success : fail}`}
    </p>
  )
}

export default TextCounter
