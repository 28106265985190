// COMPONENTS
import React from 'react'
import Section from 'layout/Section'
import MarkdownBody from 'elements/MarkdownBody'
// BOOTSTRAP
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'

// FAQ component
const FAQ = ({ content }) => {
  const { section, section: {inverted}, questionList, finalQuestion } = content

  return (
    <Section content={section}>
      <Container fluid>
        <Row className='justify-content-center'>
          {questionList.map(question => (
            <Col className='mt-4' md={10} xs={10} key={question.title}>
              <Row>
                <Col className={`text-left p-md-4 p-2 mr-md-2`}>
                  <h5>{question.title}</h5>
                  <MarkdownBody
                    markdown={question.answerMd}
                    inverted={inverted}
                  />
                </Col>
              </Row>
            </Col>
          ))}
          <Col className='mt-5' xs={10}>
            <Row>
              <Col className={`text-center p-md-4 p-2 mr-md-2 rounded`}>
                <h4>{finalQuestion.title}</h4>
                <MarkdownBody
                  addClass='text-center'
                  markdown={finalQuestion.answerMd}
                  inverted={inverted}
                />
                <Button
                  className='btn-lg btn-info'
                  href={finalQuestion.buttonLink}>
                  {finalQuestion.buttonText}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default FAQ
