// COMPONENTS
import React from 'react'
import Section from 'layout/Section'
import MarkdownBody from 'elements/MarkdownBody'
// BOOTSTRAP
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

// Reviews component
const Reviews = ({ content }) => {
  const { section, section: {inverted}, userList, reviewList } = content

  return (
    <Section content={section}>
      <Container fluid>
        <Row className='justify-content-center mb-2'>
          <Col className='mt-5' md={10} xs={12}>
            <Row className='mb-2 justify-content-center'>
              {userList.map(user => (
                <Col className='d-flex align-items-center justify-content-center' md={2} xs={4} key={user.logo}>
                  <img
                    className='rounded mx-auto d-block'
                    src={user.logo}
                    alt='logo'
                    style={{ maxWidth: '100px' }}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>

        <Row className='mt-4'>
          <Col xs={12}>
            {reviewList.map(review => (
              <Row className='justify-content-center mt-4' key={review.name}>
                <Col className='d-flex align-items-center justify-content-center' md={2} xs={12}>
                  <img
                    className='rounded mx-auto d-block'
                    src={review.image}
                    alt='logo'
                    style={{ maxWidth: '100px' }}
                  />
                </Col>
                <Col className={`text-left p-md-4 p-2 mr-md-2`} md={6} xs={12}>
                  <MarkdownBody markdown={review.quoteMd} inverted={inverted} />
                  <p className='small'>
                    {review.name}, {review.company}
                  </p>
                </Col>
              </Row>
            ))}
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default Reviews
