// COMPONENTS
import React, { useState } from 'react'
import Section from 'layout/Section'
import MarkdownBody from 'elements/MarkdownBody'
// BOOTSTRAP
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
// LOGIC
import TextCounter from './TextCounter'
import { handleEnterKey } from 'apis/Browser'
import { messageDrop } from 'apis/EmailSignup'
// ICONS
import Icon from 'elements/Icon'

// Message drop component
const MessageDrop = ({ content }) => {
  const [msg, setMsg] = useState({
    email: '', message: '',
    validEmail: false, validMsg: false, isValid: false
  })
  const { section, section: {inverted}, title, subtitle, button, icon, list, bodyMd, textbox, footer } = content
  // Calls API for message drop
  const doMsgDrop = () => messageDrop(msg, list)
  // Fired on input change
  const onInputChange  = ({ email, message }) => {
    setMsg({
      email: email ? email.target.value : msg.email,
      validEmail: email ? email.target.validity.valid : msg.validEmail,
      message: message ? message.target.value : msg.message,
      validMsg: message ? (message.target.value.length <= textbox.charLimit) : msg.validMsg,
      isValid: msg.validEmail && msg.validMsg
    })
    // console.log({msg})
  }

  return (
  <Section content={section}>
    <Container fluid>
    <Row>
      <Col className='text-center pb-2' xs={12}>
        {title && <h1 className='pt-5'>{title}</h1>}
        { icon && <Icon name={icon} /> }
        {subtitle && <h4>{subtitle}</h4>}
      </Col>
      <Col className='text-center pb-2' xs={12}>
        <MarkdownBody
          addClass='text-center'
          markdown={bodyMd}
          inverted={inverted}
        />
      </Col>
    </Row>
    <Form>
      <Form.Row>
        <Col className='text-center' xs={12} md={{ span: 8, offset: 2 }}>
          <InputGroup size='lg'>
            <Form.Control
              required
              type='email'
              placeholder='Enter your email'
              onKeyPress={e => handleEnterKey(e, doMsgDrop)}
              onChange={e => onInputChange({ email: e })}
            />
            <Form.Control.Feedback>OK!</Form.Control.Feedback>
            <InputGroup.Append>
              <Button 
                disabled={!msg.isValid}
                variant={button.color} 
                onClick={doMsgDrop}>
                {button.text}
              </Button>
            </InputGroup.Append>
          </InputGroup>
          { textbox &&
          <Form.Group controlId="exampleForm.ControlTextarea1">
            <Form.Control
              as="textarea" 
              rows="3"
              placeholder={textbox.placeholder}
              onChange={e => onInputChange({ message: e})}
            />
            <TextCounter content={textbox} chars={msg.message} />
          </Form.Group>
          }
          {footer && <Form.Text className='text-muted'>{footer}</Form.Text>}
        </Col>
      </Form.Row>
    </Form>
    </Container>
  </Section>
  )
}

export default MessageDrop