// COMPONENTS
import React from 'react'
import Section from 'layout/Section'
import SocialList from 'LandingPage/SocialList'

// Socials component
const Socials = ({ content }) => {
  const { section, socialList } = content
  return (
    <Section content={section}>
      <SocialList socialsList={socialList} />
    </Section>
  )
}

export default Socials
