// COMPONENTS
import React, { useContext } from 'react'
// import ContentContainer from 'layout/ContentContainer'
import MarkdownBody from 'elements/MarkdownBody'
import { ThemeContext } from 'layout/ThemeProvider'
// BOOTSTRAP
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'

// Price card component
const PriceCard = ({ price, trial, inverted = false }) => {
  let { themeMode, invertedTheme } = useContext(ThemeContext)
  if (inverted) [themeMode, invertedTheme] = [invertedTheme, themeMode]
  // const [ annlPricing, setAnnlPricing ] = useState(false)
  const {
    title,
    monthlyPrice,
    // annualPrice,
    descriptionMd,
    buttonText,
    buttonLink,
  } = price

  return (
    <Container className='h-100'>
      <Row className='h-100'>
        <Col
          className={`rounded text-md-left text-sm-center p-4 mb-2 bg-${invertedTheme}`}
          xs={12}>
          <Row className='h-100'>
            <Col xs={12}>
              <h3 className={trial && 'text-center'}>{title}</h3>
              <h5 className={trial && 'text-center'}>{monthlyPrice}</h5>
              {/* { annlPricing && <h5>{annualPrice}</h5> } */}
              <MarkdownBody
                addClass={trial && 'text-center'}
                markdown={descriptionMd}
                inverted
              />
            </Col>
            <Col className='align-self-end text-center' xs={12}>
              <Button
                className={`btn-lg ${trial ? 'btn-warning' : 'btn-info'}`}
                href={buttonLink}
                style={{ width: '75%' }}>
                {buttonText}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}

export default PriceCard
