// COMPONENTS
import React from 'react'
import Section from 'layout/Section'
import MarkdownBody from 'elements/MarkdownBody'
// BOOTSTRAP
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import Container from 'react-bootstrap/Container'

// Profile component
const Profile = ({ content }) => {
  const { section, section: {inverted}, title, subtitle, bodyMd, imageUrl, button } = content
  return (
    <Section content={section}>
      <Container fluid>
        <Row>
          <Col className='d-flex align-items-center justify-content-center mb-4' md={6} xs={12}>
            <img
              className='rounded-circle'
              src={imageUrl}
              alt='banner'
              style={{ width: '80%' }}
            />
          </Col>
          <Col className='pb-4' md={6} xs={12}>
            <Row>
              <Col className='text-left mb-4' xs={12}>
                <h1 className='display-3'>{title}</h1>
                <h2 className='display-6'>{subtitle}</h2>
              </Col>
              <Col className='mb-4' xs={12}>
                <MarkdownBody markdown={bodyMd} inverted={inverted} />
              </Col>
              {button && (
                <Col className='text-center' xs={12}>
                  <Button
                    className={`btn-${button.color} btn-lg btn-block rounded`}
                    href={button.link}>
                    {button.text}
                  </Button>
                </Col>
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default Profile
