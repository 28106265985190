// COMPONENTS
import React, { useContext } from 'react'
import Section from 'layout/Section'
import PriceCard from 'LandingPage/PriceCard'
import { ThemeContext } from 'layout/ThemeProvider'
// BOOTSTRAP
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'

// Pricing component
const Pricing = ({ content }) => {
  const { invertedTheme } = useContext(ThemeContext)
  // const [annlPricing, setAnnlPricing] = useState(false)
  const { section, section: {inverted}, pricingList, trialOffer } = content

  return (
    <Section content={section}>
      <Container fluid>
        <Row className='justify-content-center'>
          {/* <Col className='mt-5' xs={12}>
          <Form>
            <Form.Row>
              <Col>
                <Form.Check 
                  type='switch' 
                  label='Annual pricing'
                  id='priceSwitch'
                  onChange={ () => setAnnlPricing(!annlPricing) }
                  />
              </Col>
            </Form.Row>
          </Form>
        </Col> */}
          { pricingList && pricingList.map(price => (
            <Col className='mt-4' lg={4} md={8} xs={12} key={price.title}>
              <PriceCard price={price} inverted={inverted} />
            </Col>
          ))}
        </Row>
        { trialOffer &&
        <Row className='justify-content-center'>
          <Col
            className={`rounded mt-5 text-center p-md-4 p-4 bg-${invertedTheme}`}
            md={6}
            xs={12}>
            <PriceCard price={trialOffer} trial inverted={inverted} />
          </Col>
        </Row>
        }
      </Container>
    </Section>
  )
}

export default Pricing
